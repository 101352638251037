<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <span class="ml-3">Documents</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create">
          <i class="material-icons">add</i> <span>Document</span></a
        >
      </span>
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        striped
        hover
        select-mode="single"
        selectable
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="row">
          <a @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </a>
          <a
            v-b-modal.confirmDocumentModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </a>
          <!-- <b-button variant="primary">Edit</b-button>
          <b-button variant="danger">Delete</b-button> -->
        </template>
        <template #cell(ModifiedName)="row">
          {{ row.item.caregiverName }}
        </template>
        <template #cell(notes)="row">
          {{ commentsValue(row.item.notes) }}
        </template>
        <template #cell(image)="row">
          {{ row.item.fileInfo }}
          <span v-if="row.item.image">
            <a
              class="btn btn-success mr-3"
              @click="downloadItem(row.item.image)"
            >
              <span v-if="isDownloading">
                <v-progress-circular
                  indeterminate
                  color="isPrimary"
                ></v-progress-circular> </span
              ><span>Download</span></a
            ></span
          >
        </template>
        <template #cell(documentName)="row">
          {{ commentsValue(row.item.documentName) }}
        </template>
        <template #cell(licenceExpirationDate)="row">
          {{ dobformatexp(row.item.licenceExpirationDate) }}
        </template>
        <template #cell(createdon)="row">
          {{ dobformat(row.item.createdOn) }}
        </template>

        <template #cell(modifiedon)="row">
          {{ dobformat(row.item.modifyOn) }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <b-modal
      centered
      text
      id="confirmDocumentModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="removeDocument(docId)">
            <span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </span>
          </a>
        </span>
        <span class="green-btn">
          <a class="btn btn-danger mr-3" @click="$bvModal.hide('confirmModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="addDocument"
      title="New Document"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <v-row>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.form.documentName.$error }"
        >
          <v-text-field
            v-model="$v.form.documentName.$model"
            label="Document Name"
            maxlength="50"
            ><template #label>
              Document Name
              <span class="red">*</span>
            </template></v-text-field
          >
          <div class="invalid-feedback" v-if="$v.form.documentName.$error">
            <span v-if="$v.form.documentName.$error"
              >Document Name is required</span
            >
          </div>
        </div>
        <v-col cols="12" sm="6">
          <label>Upload Document</label>
          <br />
          <input
            type="file"
            id="file"
            accept="application/pdf,application/docx,application/xlsx,application/xlsx ,application/csv ,application/txt,application/rtf,application/doc,image/jpg, image/jpeg, image/png"
            ref="file"
            v-on:change="handleFileUpload()"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea
            filled
            maxlength="250"
            v-model="form.notes"
            auto-grow
            label="Notes"
            rows="3"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6" >
          <label>Lience Expiration Date</label>
          <br />
          <v-menu
            ref="menu"
            v-model="menu"
            class="py-3"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
              v-model="formattedExpirationDate"
                class="pt-0"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker date v-model="form.licenceExpirationDate" no-title scrollable>
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" sm="6">
          <span v-if="form.image">
            <span>{{ form.fileName }}</span>
            <br />
            <a class="btn btn-success mr-3" @click="downloadItem(form.image)">
              <span v-if="isDownloading">
                <v-progress-circular
                  indeterminate
                  color="isPrimary"
                ></v-progress-circular> </span
              ><span>Download</span></a
            ></span
          >
        </v-col>
      </v-row>
      <span class="green-btn">
        <a class="btn btn-success mr-3" @click="submit()"
          ><span
            >Save
            <span v-if="isSubmitting">
              <v-progress-circular
                indeterminate
                color="isPrimary"
              ></v-progress-circular> </span></span
        ></a>
      </span>
      <span class="green-btn">
        <a class="btn btn-danger mr-3" @click="$bvModal.hide('addDocument')"
          ><span>Cancel</span></a
        >
      </span>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import { fetchNormalApi } from "../../../core/services/request";
export default {
  mixins: [validationMixin],
  validations: {
    form: {
      documentName: { required },
    },
  },
  data() {
    return {
      date: null,
      menu: false,
      isfetching: false,
      isDownloading: false,
      isRemoving: false,
      isSending: false,
      items: [],
      fields: [
        {
          key: "documentName",
          label: "Document Name",
        },
        {
          key: "notes",
          label: "Notes",
        },
        {
          key: "fileName",
          label: "File Info",
        },
        {
          key: "licenceExpirationDate",
          label: "Licence Expiration Date",
        },
        {
          key: "createdon",
          label: "Created On",
        },
        {
          key: "caregiverName",
          label: "Created By",
        },

        {
          key: "actions",
          label: "Action",
        },
      ],
      form: {
        notes: "",
        documentName: "",
        caregiverId: "",
        createdBy: "",
        licenceExpirationDate:"",
      },
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      isSubmitting: false,
      search: "",
      docId: "",
      phoneNumber: "",
      location: "",
      id: "",
      type: "",
    };
  },

  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {});
      },
    },
    totalRow: {
      handler: function () {},
    },
  },
  mounted() {
    this.form.createdBy = this.GetAuthenticatedUser.userName;
    this.fetchData().catch((error) => {});
  },

  computed: { ...mapGetters(["editEmployee", "GetAuthenticatedUser"]),

  formattedExpirationDate() {
    if (!this.form.licenceExpirationDate) return ''; 
    const date = new Date(this.form.licenceExpirationDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const day = date.getDate().toString().padStart(2, '0');
    return `${month}/${day}/${year}`;
  } },

  methods: {
    downloadItem(url) {
      this.isDownloading = true;
      fetchNormalApi(url, null)
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "Download";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          this.isDownloading = false;
        })
        .catch(() => {
          this.isDownloading = false;
        });
    },
    commentsValue(obj) {
      if (obj.length > 10) {
        return obj.substring(0, 10).concat("...");
      } else {
        return obj;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      if (this.file.size > 400 * 1024 * 1024) {
        Swal.fire({
          title: "",
          text: "The Document size is too large kindly upload less than 400mb size!",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
        return;
      }
    },
    sendInfo(item) {
      this.docId = item;
    },
    create() {
      this.form.notes = "";
      this.form.documentName = "";
      this.form.caregiverId = "";
      this.form.licenceExpirationDate = ""; 
  this.formattedExpirationDate = ""
      this.$bvModal.show("addDocument");
    },
    dobformatexp(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    dobformat(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.form.caregiverId = this.editEmployee.id;
        this.isSubmitting = true;
        if (this.form.id) {
          this.$store
            .dispatch("updateDocument", {
              file: this.file,
              body: JSON.stringify(this.form),
            })
            .then((response) => {
              if (response) {
                this.$bvModal.hide("addDocument");
                this.fetchData();
                Swal.fire({
                  title: "",
                  text: "The Document has been Updated Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.isSubmitting = false;
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
            });
        } else {
          this.$store
            .dispatch("saveDocument", {
              file: this.file,
              body: JSON.stringify(this.form),
            })
            .then((response) => {
              if (response) {
                this.fetchData();
                this.$bvModal.hide("addDocument");
                Swal.fire({
                  title: "",
                  text: "The Document has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.reset();
                this.isSubmitting = false;
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
            });
        }
      }
    },
    async fetchData() {
      if (this.editEmployee) {
        this.isfetching = true;
        var id = this.editEmployee.id;
        await this.$store
          .dispatch("getEmployeeDocument", {
            pageNo: this.currentPage,
            pageSize: this.perPage,
            id: id,
          })
          .then((response) => {
            this.isfetching = false;
            if (response.message == "Success") {
              this.items = response.data.output;
              this.totalItems = response.data.totalRow;
            }
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      }
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    
    Edit(obj) {
  if (obj != undefined) {
    this.form = obj;
    // Set formatted expiration date based on the existing value of licenceExpirationDate
    this.formattedExpirationDate = this.dobformatexp(obj.licenceExpirationDate);
    this.$bvModal.show("addDocument");
  }
},

    removeDocument(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeDocument", {
          id: obj,
        })
        .then((response) => {
          this.$bvModal.hide("confirmDocumentModal");
          this.isRemoving = false;
          this.fetchData();
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The document has been deleted successfully",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
    ok() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSending = true;
      }
    },
    Search() {
      this.search = this.search == null ? "" : this.search;
      this.fetchData();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.error--text {
  color: rgba(252, 9, 9, 0.7) !important;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}

.requiredfield {
  color: rgba(252, 9, 9, 0.7);
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
