<template>
  <div data-app>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Caregiver </span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="editRecord">
          <i class="material-icons">add</i> <span>New Caregiver</span></a
        >
      </span>
    </div>
    <!-- <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items1"
        select-mode="single"
        :fields="fields1"
        :current-page="currentPage"
        :per-page="0"
        sort-by="lastName"
        selectable
        striped
        hover
        @row-selected="onRowSelected"
      >
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px">
            <label for="">Show : </label>
          </span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div> -->
    <div class="col-12">
      <div class="row float-right mb-15 mt-2">
        <div class="ml-15 mt-10">
          <v-btn @click="filter"><i class="fas fa-filter filter"></i></v-btn>
        </div>
      </div>
    </div>
    <div>
      <v-row>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="search"
            label="Search User"
            counter
            filled
            rounded
            clearable
            dense
            maxlength="50"
          >
            <template #label>
              <i class="fas fa-search"></i> Caregiver search
            </template>
          </v-text-field>
        </v-col>
        <v-col class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="Search"> Search </v-btn></v-col
        >
      </v-row>
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        select-mode="single"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        sort-by="lastName"
        selectable
        striped
        hover
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="row">
          <button @click="Edit(row.item.id)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </button>
          <button
            v-b-modal.confirmCareGiverModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </button>
          <!-- <b-button variant="primary">Edit</b-button>
          <b-button variant="danger">Delete</b-button> -->
        </template>
        <template #cell(dob)="row">
          {{ dobformat(row.item) }}
        </template>
        <template #cell(address)="row">
          {{ row.item.address }} {{ row.item.otherAddress }}
        </template>
        <template #cell(firstName)="row">
          {{ nameValue(row.item.firstName) }}
        </template>
        <template #cell(lastName)="row">
          {{ nameValue(row.item.lastName) }}
        </template>
        <template #cell(city)="row">
          {{ nameValue(row.item.city) }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px">
            <label for="">Show : </label>
          </span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <b-modal
      size="sm"
      left
      text
      id="filter"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div>
        <v-autocomplete
          v-model="employeeTypes"
          :items="typesdrpdown"
          label="Employee Type"
          persistent-hint
          multiple
        ></v-autocomplete>
      </div>
      <div>
        <v-autocomplete
          v-model="status"
          :items="statusdrpdown"
          label="Status "
          persistent-hint
          multiple
        ></v-autocomplete>
      </div>
      <div class="float-right">
        <span>
          <a @click="RESET" class="btn btn-primary btn-sm">Cancel</a>
        </span>
        <span class="m-4">
          <a @click="Apply" class="btn btn-primary btn-sm">Apply</a>
        </span>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="createcaregiver"
      hide-footer
      centered
      @close="closeModal"
      no-close-on-backdrop
    >
      <template slot="modal-title">
        <span v-if="action != 'Preview'">Add New Caregiver</span>
        <div
          v-else
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 2rem;
          "
        >
          <button
            class="btn btn-success font-weight-bold text-uppercase ml-5 px-4 py-2"
            @click="Previous"
          >
            Previous
          </button>
          <button
            class="btn btn-success font-weight-bold text-uppercase px-8 py-2"
            type="button"
            @click="Next"
          >
            Next
          </button>
        </div></template
      >
      <createwizard
        :action="action"
        :ID="ID"
        @employeeSent="closeModal"
      ></createwizard>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmCareGiverModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(careGiverId)"
            ><span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span
          ></a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmCareGiverModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import createwizard from "./addCareGiver.vue";
import { mapGetters } from "vuex";
import datatable from "../../components/datatable.vue";
import readXlsxFile from "read-excel-file";
import { integer } from "vuelidate/lib/validators";
export default {
  //  DataTables,SMS,  calender,mail,
  components: {
    createwizard,
    datatable,
  },
  data() {
    return {
      action: "",
      careGiverId: "",
      isfetching: false,
      showChangePass: true,
      clientid: "",
      ID: "",
      items: [],
      items1: [],
      isRemoving: false,
      status: [],
      employeeTypes: [],
      statusdrpdown: [
        { value: "Active", text: "Active" },
        { value: "Inactive", text: "Inactive" },
        { value: "Terminated", text: "Terminated" },
      ],
      typesdrpdown: [
        { value: "Agency Caregiver", text: "Agency Caregiver" },
        { value: "Family Referred", text: "Family Referred" },
        { value: "JFS", text: "JFS" },
        { value: "Home Health", text: "Home Health" },
        { value: "Other", text: "Other" },
      ],
      fields: [
        {
          key: "lastName",
          label: "Last Name",
        },
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "empID",
          label: "Employee ID",
        },
        {
          key: "dob",
          label: "DOB",
        },
        {
          key: "address",
          label: "Address",
        },

        {
          key: "employeeType",
          label: "Employee Type",
        },

        {
          key: "actions",
          label: "Action",
        },
      ],
      fields1: [
        {
          key: "lastName",
          label: "Last Name",
        },
        {
          key: "firstName",
          label: "First Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "empID",
          label: "Employee ID",
        },
        {
          key: "dob",
          label: "DOB",
        },
        {
          key: "address",
          label: "Address",
        },

        {
          key: "employeeType",
          label: "Employee Type",
        },

        {
          key: "longitude",
          label: "Longitutde",
        },
        {
          key: "latitude",
          label: "Latitude",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,
      search: "",
    };
  },
  mounted() {
    if (this.GetAuthenticatedUser.userType == "JFS") {
      this.typesdrpdown = this.typesdrpdown.filter((x) => x.value == "JFS");
      this.employeeTypes = this.typesdrpdown[0].value;
    } else if (this.GetAuthenticatedUser.userType == "Home Health") {
      this.typesdrpdown = this.typesdrpdown.filter(
        (x) => x.value == "Home Health"
      );
      this.employeeTypes = this.typesdrpdown[0].value;
    } else {
      this.payersList = this.PayersList;
    }
    this.fetchData().catch((error) => {
      console.error(error);
    });
  },
  computed: {
    ...mapGetters([
      "Employees",
      "totalEmployeesRow",
      "editEmployee",
      "GetAuthenticatedUser",
    ]),
  },
  methods: {
    async Next() {
      var l = this.items.sort((a, b) => a.lastName.localeCompare(b.lastName));
      var index = l.indexOf(l.find((x) => x.id == this.editEmployee.id)) + 1;
      if (index < l.length) {
        this.closeModal();
        this.Edit(l[index].id);
      }
    },
    async Previous() {
      var l = this.items.sort((a, b) => a.lastName.localeCompare(b.lastName));
      var index = l.indexOf(l.find((x) => x.id == this.editEmployee.id)) - 1;
      if (index >= 0 && index < l.length) {
        this.closeModal();
        this.Edit(l[index].id);
      }
    },
    RESET() {
      this.payers = [];
      this.status = [];
      if (this.GetAuthenticatedUser.userType == "JFS") {
        this.typesdrpdown = this.typesdrpdown.filter((x) => x.value == "JFS");
        this.employeeTypes = this.typesdrpdown[0].value;
      } else if (this.GetAuthenticatedUser.userType == "Home Health") {
        this.typesdrpdown = this.typesdrpdown.filter(
          (x) => x.value == "Home Health"
        );
        this.employeeTypes = this.typesdrpdown[0].value;
      } else {
        this.payersList = this.PayersList;
      }
      this.fetchData();
      this.$bvModal.hide("filter");
    },
    Apply() {
      this.fetchData();
      this.$bvModal.hide("filter");
    },
    filter() {
      this.$bvModal.show("filter");
    },
    addressValue(obj) {
      if (obj && obj.length > 50) {
        return obj.substring(0, 50).concat("...");
      } else {
        return obj;
      }
    },
    nameValue(obj) {
      if (obj && obj.length > 10) {
        return obj.substring(0, 10).concat("...");
      } else {
        return obj;
      }
    },
    dobformat(obj) {
      var date = new Date(obj.dob);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    async Search() {
      this.fetchData();
    },
    async fetchData() {
      this.isfetching = true;
      this.search = this.search == null ? "" : this.search;
      await this.$store
        .dispatch("getEmployee", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          filter: this.search,
          status: this.status,
          employeeType: this.employeeTypes,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.isfetching = false;
            this.items = response.data.output;
            this.totalItems = response.data.totalRow;
          }
        })
        .catch((ex) => {
          this.isfetching = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          });
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    onRowSelected(items) {
      this.Edit(items[0].id);
    },
    sendInfo(item) {
      this.careGiverId = item;
    },
    async remove(obj) {
      this.isRemoving = true;
      await this.$store
        .dispatch("removecareGiver", {
          id: obj,
        })
        .then((response) => {
          this.isRemoving = false;
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "This caregiver has been deleted successfully",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
            });
          }
          this.fetchData();
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
      this.$bvModal.hide("confirmCareGiverModal");
    },
    closeModal() {
      this.action = "Create";
      this.$bvModal.hide("createcaregiver");
      this.fetchData();
    },
    editRecord() {
      this.action = "Create";
      this.$store.dispatch("editEmployee", null);
      this.$bvModal.show("createcaregiver");
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    Schedule() {
      this.$bvModal.show("calender");
    },
    async Edit(obj) {
      this.$store.dispatch("emptyEditClient", null);
      this.isfetching = true;
      if (obj) {
        this.ID = obj;
        await this.$store.dispatch("editEmployee", obj).then(() => {
          this.action = "Preview";
          this.isfetching = false;
          this.$bvModal.show("createcaregiver");
        });
      }
    },
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalClientRow: {
      handler: function () {
        this.items = this.clients;
      },
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}

.edit_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #fb8c00 !important;
}
.del_btn {
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #bf360c !important;
}
</style>
