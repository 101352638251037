<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">Staffing</span>
      </h3>
    </div>

    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        v-if="this.editClient"
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fields"
        striped
        hover
        select-mode="single"
        selectable
      >
        <template #cell(caregiver)="row">
          {{ caregivers(row.item.casegiverId) }}
        </template>
        <template #cell(startDate)="row">
          {{ dobformat(row.item.startDate) }}
        </template>
        <template #cell(endDate)="row">
          {{ dobformat(row.item.endDate) }}
        </template>
        <template #cell(lastVisitDate)="row">
          {{ dobformat(row.item.lastVisitDate) }}
        </template>
        <template #cell(service)="row">
          {{ service(row.item) }}
        </template>
      </b-table>
      <b-table
        v-if="this.editEmployee"
        class="table table-bordered"
        show-empty
        :items="items"
        :fields="fieldsCaregiver"
        striped
        hover
        select-mode="single"
        selectable
      >
        <template #cell(client)="row">
          {{ clients(row.item.clientId) }}
        </template>
        <template #cell(startDate)="row">
          {{ dobformat(row.item.startDate) }}
        </template>
        <template #cell(endDate)="row">
          {{ dobformat(row.item.endDate) }}
        </template>
        <template #cell(lastVisitDate)="row">
          {{ dobformat(row.item.lastVisitDate) }}
        </template>
        <template #cell(service)="row">
          {{ service(row.item) }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            :total-rows="totalItems"
            v-if="perPage != '-1'"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  props: {
    clientIdprop: String,
  },
  data() {
    return {
      isfetching: false,
      search: "",
      staffingid: "",
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      showChangePass: true,
      items: [],
      fieldsCaregiver: [
        {
          key: "client",
          label: "Client",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "startDate",
          label: "Start Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },
        // {
        //   key: "service",
        //   label: "Service/SubCode",
        // },
        // {
        //   key: "hoursTogather",
        //   label: "Hours Together",
        // },
        {
          key: "lastVisitDate",
          label: "Last Visit Date",
        },
      ],
      fields: [
        {
          key: "caregiver",
          label: "Caregiver",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "startDate",
          label: "Start Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },
        // {
        //   key: "service",
        //   label: "Service/SubCode",
        // },
        // {
        //   key: "hoursTogather",
        //   label: "Hours Together",
        // },
        {
          key: "lastVisitDate",
          label: "Last Visit Date",
        },
      ],
    };
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
    // this.$store.dispatch("getSubCodeList");
    this.$store.dispatch("getCareGiverList");
    this.$store.dispatch("getClientList");
  },

  computed: {
    ...mapGetters([
      "staffing",
      "totalStaffingRow",
      "careGiverList",
      "ClientsList",
      "editClient",
      "subcodeList",
      "editEmployee",
    ]),
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
  },
  methods: {
    dobformat(obj) {
      if (obj != null) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },

    caregivers(obj) {
      if (this.careGiverList && this.careGiverList.find((x) => x.id == obj)) {
        return this.careGiverList
          .find((x) => x.id == obj)
          .name.substring(0, 10)
          .concat("...");
      } else {
        return null;
      }
    },
    clients(obj) {
      if (this.ClientsList && this.ClientsList.find((x) => x.id == obj)) {
        return this.ClientsList.find((x) => x.id == obj).name;
      } else {
        return null;
      }
    },
    service(obj) {
      var subcodes = "";

      obj.casegiverAssociatedSubcodes.forEach((e, i) => {
        subcodes += e.subCode;
        if (i != obj.casegiverAssociatedSubcodes.length - 1) {
          subcodes += ", ";
        }
      });

      return subcodes;
    },
    async fetchData() {
      if (this.editClient || this.editEmployee) {
        this.isfetching = true;
        if (this.editClient) {
          var id = this.editClient.id;
          this.$store.dispatch("getSubCodeListbyClientId", id);
        } else {
          var id = this.editEmployee.id;
        }
        await this.$store
          .dispatch("getStaffing", {
            pageNo: this.currentPage,
            pageSize: this.perPage,
            id: id,
          })
          .then((response) => {
            if (response.message == "Success") {
              this.isfetching = false;
              this.items = this.staffing;
              this.totalItems = this.totalStaffingRow;
            }
          })
          .catch((ex) => {
            this.isfetching = false;
            
          });
      }
    },
    Search() {
      this.fetchData();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    destroyed() {
      this.$store.dispatch("editStaffing", null);
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
</style>
