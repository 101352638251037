<template>
  <div>
    <div class="col-md-6">
      <span>Caregiver Mobile App Credentials.</span>
      <div class="col-md-12 mb-form" :class="{ invalid: $v.form.email.$error }">
        <v-text-field
          v-model.trim="$v.form.email.$model"
          required
          :disabled="!isEditAble"
          maxlength="50"
        >
          <template #label>
            Email
            <span class="red">*</span>
          </template>
        </v-text-field>
        <v-col
          cols="12"
          sm="1"
          v-if="!isEditAble"
          @click="isEditAble = !isEditAble"
        >
          <span>
            <a class="btn btn-success mr-3">
              <i class="material-icons">edit</i></a
            >
          </span>
        </v-col>
        <v-col cols="12" sm="1" v-if="isEditAble" @click="submit">
          <span>
            <a class="btn btn-success mr-3">
              <i class="material-icons">save</i></a
            >
          </span>
        </v-col>

        <div class="invalid-feedback" v-if="$v.form.email.$error">
          <span v-if="$v.form.email.$error">Email is In_valid</span>
        </div>
        <b-button
          @click="SendCredentials"
          size="lg"
          variant="success"
          class="mt-5"
        >
          Send credentials update
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email },
    },
  },
  data() {
    return {
      isEditAble: false,
      form: {
        email: "",
      },
    };
  },
  computed: { ...mapGetters(["editEmployee"]) },
  mounted() { 
    this.form.email = this.editEmployee.email;
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("putEmail", {
            ID: this.editEmployee.id,
            Email: this.form.email,
          })
          .then((response) => { 
            if (response.status) {
              Swal.fire({
                title: "",
                text: "Email Updated Successfully",
                icon: "success",
                confirmButtonText: "OK",
              });
              this.isEditAble = false;
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              });
            }
          });
      }
    },
    SendCredentials() {
      this.$store
        .dispatch("ResendCredentials", this.form.email)
        .then((response) => { 
          if (response.status) {
            Swal.fire({
              title: "",
              text: "Credentials Sent Successfully",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "",
              text: response.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        });
    },
  },
};
</script>

<style>
</style>