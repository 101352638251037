<template>
  <div>
    <!-- <div v-if="this.clientIdprop == '' || this.clientIdprop == null">
      <center style="color:red">Please Fill Client Form first</center>
    </div> -->
    <div class="row float-right">
      <span class="float-right green-btn mb-5">
        <a v-if="!hireDate" class="btn btn-success mr-3" @click="saveHireDate">
          <span><i class="far fa-save"></i>Save</span>
        </a>
        <a
          v-if="disableHireDate"
          class="btn btn-success mr-3"
          @click="editHireDate"
        >
          <span> <i class="far fa-eye edit_btn "></i> Edit</span>
        </a>
        <a
          v-if="!disableHireDate && hireDate"
          class="btn btn-success mr-3"
          @click="saveHireDate"
        >
          <span> <i class="far fa-eye edit_btn"></i> Update</span>
        </a>
      </span>
    </div>
    <div class="row">
      <div class="form-group col-md-6 mb-form">
        <label>Hire Date</label> <span class="red">*</span>
        <date-picker
          ref="dp1"
          :disabled="disableHireDate"
          @focusin.native="onfocusin"
          placeholder="Choose Hire Date"
          :config="datePickerOptions"
          v-model="hireDate"
        ></date-picker>
      </div>
      <!-- <div class="form-group col-md-6 mb-form">
        <v-text-field
          disabled
          label="Total Hours Worked"
          v-model.trim="totalHours"
          maxlength="100"
        ></v-text-field>
      </div> -->
    </div>

    <!-- <div class="page-title pageheading" style="height: 50px">
      <h3 class="">
        <span class="ml-3">Pay Rates</span>
      </h3>
      <br />
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create">
          <i class="material-icons">add</i> <span>New PayRate</span>
        </a>
      </span>
      <span>
        Enter any pay rates specific to this caregiver (if differ from default
        set in Office Settings).
      </span>
    </div>
    <div class="container mt-10" style="background-color: #ffffff">
      <b-table
        class="table table-bordered"
        show-empty
        :items="PayRateitems"
        :fields="PayRatefields"
        striped
        hover
        select-mode="single"
        selectable
        @row-selected="onPayRowSelected"
      >
        <template #cell(actions)="row">
          <a @click="EditPayRate(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </a>
          <a
            v-b-modal.confirmPayModal
            @click="sendPayInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </a>
        </template>
        <template #cell(startDate)="row">
          {{ dobformat(row.item.startDate) }}
        </template>
        <template #cell(endDate)="row">
          {{ dobformat(row.item.endDate) }}
        </template>
      </b-table>
    </div>
    <v-divider></v-divider> -->
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="">
        <span class="ml-3">Caregiver Compliance</span>
      </h3>
      <br />
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="createCompliance">
          <i class="material-icons">add</i> <span>New Compliance Item</span>
        </a>
      </span>
      <span>
        Any certifications/licenses pertinent to this caregiver's job, including
        their date of expiry ,can be added here.
      </span>
    </div>
    <div class="container mt-10" style="background-color: #ffffff">
      <b-table
        class="table table-bordered"
        show-empty
        :items="Complianceitems"
        :fields="Compliancefields"
        striped
        hover
        select-mode="single"
        selectable
        @row-selected="onComplianceSelected"
      >
        <template #cell(actions)="row">
          <a @click="EditCompliance(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </a>
          <a
            v-b-modal.confirmComplainceModal
            @click="sendComplianceInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </a>
        </template>
        <template #cell(originDate)="row">
          {{ dobformat(row.item.originDate) }}
        </template>
        <template #cell(expireDate)="row">
          {{ dobformat(row.item.expireDate) }}
        </template>
        <template #cell(notes)="row">
          {{ nameValue(row.item.notes) }}
        </template>
      </b-table>
    </div>
    <b-modal
      centered
      text
      id="confirmComplainceModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a
            class="btn btn-success mr-3"
            @click="removeCompliance(complianceId)"
            ><span
              >YES I'M SURE
              <span v-if="isRemoving">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmComplainceModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
    <!-- <b-modal
      centered
      text
      id="confirmPayModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="removePay(payId)"
            ><span
              >YES I'M SURE
              <span v-if="isRemoving">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular> </span></span
          ></a>
        </span>
        <span class="green-btn">
          <a
            class="btn btn-danger mr-3"
            @click="$bvModal.hide('confirmPayModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal> -->
    <!-- <b-modal
      size="xl"
      id="addPayRate"
      title="New Pay Rate "
      centered
      no-close-on-backdrop
      hide-footer
    >
      <v-row>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.formPayRate.subCodesId.$error }"
        >
          <label> SubCode/Service </label> <span class="red">*</span>
          <b-form-select
            class="form-control"
            v-model.trim="$v.formPayRate.subCodesId.$model"
            value-field="id"
            text-field="subCode"
            @change="subcodeselect()"
            required
            :options="subcodeList"
          ></b-form-select>
          <div class="invalid-feedback" v-if="$v.formPayRate.subCodesId.$error">
            <span v-if="$v.formPayRate.subCodesId.$error"
              >SubCodes is required</span
            >
          </div>
        </div>

        <v-col cols="12" sm="3">
          <label> Pay RATE </label>
          <b-form-select
            class="form-control"
            v-model="formPayRate.rateType"
            :rules="['Required']"
            required
            :options="rateDrpDownvalue"
          ></b-form-select>
        </v-col>
        <v-col v-if="formPayRate.rateType == 'Default'" cols="12" sm="3">
          <v-text-field
            disabled
            v-model="formPayRate.rate"
            label="Pay RATE"
            maxlength="50"
          ></v-text-field>
        </v-col>
        <v-col v-else cols="12" sm="3">
          <v-text-field
            v-model="formPayRate.cprate"
            label="Custom Pay RATE"
            maxlength="4"
            type="number"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>
        </v-col>
 
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.formPayRate.startDate.$error }"
        >
          <label>Start Date</label> <span class="red">*</span>
          <date-picker
            ref="dp1"
            @focusin.native="onfocusin"
            class="dpStartDate"
            placeholder="Choose Start Date"
            :config="datePickerOptions"
            @dp-change="dataStartChange"
            v-model.trim="$v.formPayRate.startDate.$model"
          ></date-picker>
          <div class="invalid-feedback" v-if="$v.formPayRate.startDate.$error">
            <span v-if="$v.formPayRate.startDate.$error"
              >Start Date is required</span
            >
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.formPayRate.endDate.$error }"
        >
          <label>End Date</label> <span class="red">*</span>
          <date-picker
            ref="dp1"
            @focusin.native="onfocusin"
            placeholder="Choose End Date"
            class="dpdataEndDate"
            :config="datePickerOptions"
            v-model.trim="$v.formPayRate.endDate.$model"
          ></date-picker>
          <div class="invalid-feedback" v-if="$v.formPayRate.endDate.$error">
            <span v-if="$v.formPayRate.endDate.$error"
              >End Date is required</span
            >
          </div>
        </div>
        <v-col cols="12" sm="6">
          <v-textarea
            filled
            v-model="formPayRate.notes"
            auto-grow
            label="Notes"
            maxlength="250"
            rows="3"
            row-height="30"
            shaped
          ></v-textarea>
        </v-col>
      </v-row>
      <span class="green-btn">
        <a class="btn btn-success mr-3" @click="submitPayRate()"
          ><span
            >Save
            <span v-if="isSubmitting">
              <v-progress-circular
                indeterminate
                color="isPrimary"
              ></v-progress-circular> </span></span
        ></a>
      </span>
      <span class="green-btn">
        <a class="btn btn-danger mr-3" @click="$bvModal.hide('addPayRate')"
          ><span>Cancel</span></a
        >
      </span>
    </b-modal> -->
    <b-modal
      size="xl"
      id="addComplianceItem"
      title="New Compliance Item "
      centered
      no-close-on-backdrop
      hide-footer
    >
      <v-row>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.formCompliance.complianceType.$error }"
        >
          <label> Compliance Type </label> <span class="red">*</span>
          <b-form-select
            class="form-control"
            v-model.trim="$v.formCompliance.complianceType.$model"
            required
            :options="complianceType"
          ></b-form-select>
          <div
            class="invalid-feedback"
            v-if="$v.formCompliance.complianceType.$error"
          >
            <span v-if="$v.formCompliance.complianceType.$error"
              >Compliance Type is required</span
            >
          </div>
        </div>

        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.formCompliance.originDate.$error }"
        >
          <label>Origin Date</label> <span class="red">*</span>
          <date-picker
            ref="dpor"
            class="dpdatainicio"
            v-model.trim="$v.formCompliance.originDate.$model"
            @focusin.native="onfocusin"
            placeholder="Choose Origin Date"
            :config="datePickerOptions"
            @dp-change="dataInicioChange"
          ></date-picker>
          <div
            class="invalid-feedback"
            v-if="$v.formCompliance.originDate.$error"
          >
            <span v-if="$v.formCompliance.originDate.$error"
              >Origin Date is required</span
            >
          </div>
        </div>
        <div
          class="form-group col-md-6 mb-form"
          :class="{ invalid: $v.formCompliance.expireDate.$error }"
        >
          <label>Expiration Date</label> <span class="red">*</span>
          <date-picker
            ref="dpex"
            @focusin.native="onfocusin"
            class="dpdatafim"
            placeholder="Choose Expiration Date"
            :config="datePickerOptionsexp"
            v-model.trim="$v.formCompliance.expireDate.$model"
          ></date-picker>
          <div
            class="invalid-feedback"
            v-if="$v.formCompliance.expireDate.$error"
          >
            <span v-if="$v.formCompliance.expireDate.$error"
              >Expire Date is required</span
            >
          </div>
        </div>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="formCompliance.complianceNumber"
            label="License/Compliance No."
            type="number"
            :max="20"
            maxlength="20"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <label>Browse</label>
          <br />
          <input
            type="file"
            id="file"
            ref="file"
            accept="application/pdf,application/docx,application/xlsx,application/xlsx ,application/csv ,application/txt,application/rtf,application/doc,image/jpg, image/jpeg, image/png"
            v-on:change="handleFileUpload()"
          />
          <span v-if="formCompliance.image">
            {{ formCompliance.fileInfo }}
            <br />
            <a
              class="btn btn-success mr-3"
              @click="downloadItem(formCompliance.image)"
            >
              <span v-if="isDownloading">
                <v-progress-circular
                  indeterminate
                  color="isPrimary"
                ></v-progress-circular> </span
              ><span>Download</span></a
            ></span
          >
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea
            filled
            v-model="formCompliance.notes"
            auto-grow
            label="Notes"
            rows="3"
            row-height="30"
            shaped
            maxlength="250"
          ></v-textarea>
        </v-col>
      </v-row>
      <span class="green-btn">
        <a class="btn btn-success mr-3" @click="submitCompliance()"
          ><span
            >Save
            <span v-if="isSubmitting">
              <v-progress-circular
                indeterminate
                color="isPrimary"
              ></v-progress-circular> </span></span
        ></a>
      </span>
      <span class="green-btn">
        <a
          class="btn btn-danger mr-3"
          @click="$bvModal.hide('addComplianceItem')"
          ><span>Cancel</span></a
        >
      </span>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { fetchNormalApi } from "../../../core/services/request";
var today = new Date();
export default {
  mixins: [validationMixin],
  validations: {
    // formPayRate: {
    //   subCodesId: { required },
    //   startDate: { required },
    //   endDate: { required },
    // },
    formCompliance: {
      complianceType: { required },
      originDate: { required },
      expireDate: { required },
    },
  },
  props: {
    clientIdprop: String,
  },
  data() {
    return {
      disableHireDate: false,
      disablePayrate: true,
      hireDate: "",
      totalHours: "",
      isfetching: false,
      isDownloading: false,
      isRemoving: false,
      isSubmitting: false,
      search: "",
      payId: "",
      complianceId: "",
      currentPage: 1,
      perPage: 50,
      showChangePass: true,
      datePickerOptions: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
      },
      datePickerOptionsexp: {
        format: "MM/DD/YYYY",
        useCurrent: false,
        showTodayButton: true,
        showClear: true,
        showClose: true,
        minDate: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          23,
          59,
          59
        ),
      },
      PayRateitems: [],
      PayRatefields: [
        {
          key: "subCodeName",
          label: "Service Type",
        },
        {
          key: "rate",
          label: "Rate",
        },
        {
          key: "startDate",
          label: "Start Date",
        },
        {
          key: "endDate",
          label: "End Date",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      Complianceitems: [],
      Compliancefields: [
        {
          key: "complianceType",
          label: "Compliance Type",
        },
        {
          key: "originDate",
          label: "Origin Date",
        },
        {
          key: "expireDate",
          label: "Expiration Date",
        },
        {
          key: "notes",
          label: "Notes",
        },
        {
          key: "complianceNumber",
          label: "Licennse/Compliance No",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      // formPayRate: {
      //   subCodesId: "",
      //   notes: "",
      //   endDate: "",
      //   startDate: "",
      //   rate: "",
      //   caregiverRate: "",
      //   rateType: "Default",
      // },
      complianceType: [
        { value: "", text: "Please Select compliance Type" },
        { value: "CPR", text: "CPR" },
        { value: "Automobile Insurance", text: "Automobile Insurance" },
        { value: "Driver License", text: "Driver License" },
        { value: "CNA/HHA", text: "CNA/HHA" },
        { value: "Professional License", text: "Professional License" },
        { value: "Alien Card", text: "Alien Card" },
        { value: "LVN", text: "LVN" },
        { value: "RN", text: "RN" },
      ],
      file: "",
      formCompliance: {
        notes: "",
        complianceNumber: "",
        expireDate: "",
        originDate: "",
        complianceType: "",
        caregiverId: "",
      },
      rateDrpDownvalue: [
        { value: "Default", text: "Default Pay Rate" },
        { value: "Custom", text: "Custom Pay Rate" },
      ],
    };
  },
  mounted() {
    this.fetchData().catch((error) => {});
    this.$store.dispatch("getSubCodeList");
  },

  computed: { ...mapGetters(["subcodeList", "editEmployee"]) },
  methods: {
    downloadItem(url) {
      this.isDownloading = true;
      fetchNormalApi(url, null)
        .then((response) => response.blob())
        .then((blob) => {
            var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "Download";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          this.isDownloading = false;
        })
        .catch(() => {
          this.isDownloading = false;
        });
    },
    dataInicioChange: (e) => {
      document.querySelectorAll(".dpdatafim")[0].__vue__.dp.minDate(e.date._d);

      var expDate = new Date(document.querySelectorAll(".dpdatafim")[0].value);
      var originDate = new Date(e.date._d);

      if (expDate <= originDate) {
        Swal.fire({
          title: "",
          text: "The expiration  Date is grater than origin Date Date!",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
        document.querySelectorAll(".dpdatafim")[0].value = null;
      }
    },
    dataStartChange: (e) => {
      document
        .querySelectorAll(".dpdataEndDate")[0]
        .__vue__.dp.minDate(e.date._d);
      var endDate = new Date(
        document.querySelectorAll(".dpdataEndDate")[0].value
      );
      var startDate = new Date(e.date._d);

      if (endDate <= startDate) {
        Swal.fire({
          title: "",
          text: "The endDate Date is less than startDate Date!",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
        document.querySelectorAll(".dpdataEndDate")[0].value = null;
      }
    },
    editHireDate() {
      this.disableHireDate = false;
    },
    saveHireDate() {
      var date = new Date(this.hireDate);
       let hoursDiff =
            date.getHours() - date.getTimezoneOffset() / 60;
          let minutesDiff =
            (date.getHours() - date.getTimezoneOffset()) % 60;
          date.setHours(hoursDiff);
          date.setMinutes(minutesDiff);
     
      this.$store
        .dispatch("saveHireDate", {
          id: this.editEmployee.id,
          hireDate: date.toUTCString(),
        })
        .then((response) => {
          if (response.message == "Success") {
            this.disableHireDate = true;

            Swal.fire({
              title: "",
              text: "This employee has been updated successfully",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        });
    },
    nameValue(obj) {
      if (obj && obj.length > 20) {
        return obj.substring(0, 20).concat("...");
      } else {
        return obj;
      }
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      if (this.file.size > 400 * 1024 * 1024) {
        Swal.fire({
          title: "",
          text: "The File size is too large kindly upload less than 400MB size!",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
        return;
      }
    },
    // subcodeselect() {
    //   this.formPayRate.rate = this.subcodeList.find(
    //     (x) => x.id == this.formPayRate.subCodesId
    //   ).rate;
    //   this.disablePayrate = false;
    // },
    onfocusin(obj) {},

    dobformat(obj) {
      var date = new Date(obj);
      return (
        (date.getMonth() > 8
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)) +
        "/" +
        (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
        "/" +
        date.getFullYear()
      );
    },
    sendComplianceInfo(item) {
      this.complianceId = item;
    },
    sendPayInfo(item) {
      this.payId = item;
    },
    // create() {
    //   (this.formPayRate.subCodesId = ""),
    //     (this.formPayRate.notes = ""),
    //     (this.formPayRate.endDate = ""),
    //     (this.formPayRate.startDate = ""),
    //     (this.formPayRate.rate = ""),
    //     this.$bvModal.show("addPayRate");
    // },
    createCompliance() {
      (this.formCompliance.notes = ""),
        (this.formCompliance.complianceNumber = ""),
        (this.formCompliance.expireDate = ""),
        (this.formCompliance.originDate = ""),
        (this.formCompliance.complianceType = ""),
        (this.formCompliance.caregiverId = ""),
        this.$bvModal.show("addComplianceItem");
    },
    async fetchData() {
      if (this.editEmployee) {
        this.isfetching = true;
        var id = this.editEmployee.id;
        // await this.$store.dispatch("getEmployeePayRate", {
        //     pageNo: this.currentPage,
        //     pageSize: this.perPage,
        //     id: id,
        //   })
        //   .then((response) => {
        //     if (response.message == "Success") {
        //       this.isfetching = false;
        //       this.PayRateitems = response.data.output;
        //       this.totalItems = response.totalRow;
        //     }
        //   })
        //   .catch((ex) => {
        //     this.isfetching = false;
        //   });

        await this.$store
          .dispatch("getEmployeeHireDate", id)
          .then((response) => {
            if (response.hireDate) {
              this.hireDate = new Date(response.hireDate);
              this.disableHireDate = true;
            }
          })
          .catch((ex) => {
            this.isfetching = false;
          });
        await this.$store
          .dispatch("getEmployeeCompliance", {
            pageNo: this.currentPage,
            pageSize: this.perPage,
            id: id,
          })
          .then((response) => {
            if (response.message == "Success") {
              this.isfetching = false;
              this.Complianceitems = response.data.output;
              this.totalItems = response.totalRow;
            }
          })
          .catch((ex) => {
            this.isfetching = false;
          });
      }
    },

    onPayRowSelected(items) {
      this.EditPayRate(items[0]);
    },
    onComplianceSelected(items) {
      this.EditCompliance(items[0]);
    },

    // EditPayRate(obj) {
    //   if (obj != undefined) {
    //     this.formPayRate = obj;
    //     this.formPayRate.cprate = this.formPayRate.rate;
    //     this.formPayRate.rate =  this.formPayRate.subCodes.rate
    //     this.formPayRate.startDate = this.dobformat(this.formPayRate.startDate);
    //     this.formPayRate.endDate = this.dobformat(this.formPayRate.endDate);
    //     this.$bvModal.show("addPayRate");
    //   }
    // },
    EditCompliance(obj) {
      if (obj != undefined) {
        this.formCompliance = obj;
        this.formCompliance.expireDate = this.dobformat(
          this.formCompliance.expireDate
        );
        this.formCompliance.originDate = this.dobformat(
          this.formCompliance.originDate
        );
        this.$bvModal.show("addComplianceItem");
      }
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },

    // submitPayRate() {
    //   this.$v.$touch();
    //   if (!this.$v.formPayRate.$invalid) {
    //     this.formPayRate.endDate = new Date(this.formPayRate.endDate + " EDT");
    //     this.formPayRate.startDate = new Date(
    //       this.formPayRate.startDate + " EDT"
    //     );
    //     this.formPayRate.caregiverId = this.editEmployee.id;
    //     this.isSubmitting = true;
    //     if(this.formPayRate.rateType == "Custom"){
    //         this.formPayRate.rate=parseFloat(this.formPayRate.cprate);
    //     }
    //     this.formPayRate.rate = parseFloat(this.formPayRate.rate);
    //     if (this.formPayRate.id) {
    //       this.$store
    //         .dispatch("updateEmployeePayRate", this.formPayRate)
    //         .then((response) => {
    //           if (response.message == "Success") {
    //             this.fetchData();
    //             Swal.fire({
    //               title: "",
    //               text: "The Employee Pay rate has been Updated Successfully!",
    //               icon: "success",
    //               confirmButtonClass: "btn btn-secondary",
    //             });
    //             this.isSubmitting = false;
    //             this.$bvModal.hide("addPayRate");
    //           } else if (response.message == "Failure") {
    //             Swal.fire({
    //               title: "",
    //               text: "Error!",
    //               icon: "error",
    //               confirmButtonClass: "btn btn-danger",
    //             });
    //           }
    //           this.isSubmitting = false;
    //         })
    //         .catch((ex) => {
    //           this.isSubmitting = false;
    //         });
    //     } else {
    //       this.$store
    //         .dispatch("saveEmployeePayRate", this.formPayRate)
    //         .then((response) => {
    //           if (response.message == "Success") {
    //             this.fetchData();
    //             Swal.fire({
    //               title: "",
    //               text: "The Employee Pay rate has been Created Successfully!",
    //               icon: "success",
    //               confirmButtonClass: "btn btn-secondary",
    //             });
    //             this.isSubmitting = false;
    //             this.$bvModal.hide("addPayRate");
    //           } else if (response.message == "Failure") {
    //             Swal.fire({
    //               title: "",
    //               text: "Error!",
    //               icon: "error",
    //               confirmButtonClass: "btn btn-danger",
    //             });
    //           }

    //           this.isSubmitting = false;
    //         })
    //         .catch((ex) => {
    //           this.isSubmitting = false;
    //         });
    //     }
    //     this.fetchData();
    //     this.$bvModal.show("addPayRate");
    //   }
    // },
    submitCompliance() {
      this.$v.$touch();
      if (!this.$v.formCompliance.$invalid) {
        this.formCompliance.originDate = new Date(
          this.formCompliance.originDate
        );
        this.formCompliance.expireDate = new Date(
          this.formCompliance.expireDate
        );
        this.formCompliance.caregiverId = this.editEmployee.id;
        this.isSubmitting = true;
        if (this.formCompliance.id) {
          this.$store
            .dispatch("updateCompliance", {
              file: this.file,
              body: JSON.stringify(this.formCompliance),
            })
            .then((response) => {
              if (response) {
                this.fetchData();
                Swal.fire({
                  title: "",
                  text: "The Compliance has been Updated Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.isSubmitting = false;
                this.$bvModal.hide("addComplianceItem");
                this.$emit("staffingeSent");
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
            });
        } else {
          this.$store
            .dispatch("saveCompliance", {
              file: this.file,
              body: JSON.stringify(this.formCompliance),
            })
            .then((response) => {
              if (response) {
                this.$bvModal.hide("addComplianceItem");
                this.fetchData();

                Swal.fire({
                  title: "",
                  text: "The Compliance has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                this.reset();
                this.isSubmitting = false;
              } else if (response.message == "Failure") {
                Swal.fire({
                  title: "",
                  text: "Error!",
                  icon: "error",
                  confirmButtonClass: "btn btn-danger",
                });
              }
              this.isSubmitting = false;
            })
            .catch((ex) => {
              this.isSubmitting = false;
            });
        }
      }
    },
    removeCompliance(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removeEmployeeCompliance", {
          id: obj,
        })
        .then((response) => {
          this.$bvModal.hide("confirmComplainceModal");
          this.isRemoving = false;
          this.fetchData();
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The Compliance has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        });
    },
    // removePay(obj) {
    //   this.isRemoving = true;
    //   this.$store
    //     .dispatch("removeEmployeePayRate", {
    //       id: obj,
    //     })
    //     .then((response) => {
    //       this.$bvModal.hide("confirmPayModal");
    //       this.fetchData();
    //       this.isRemoving = false;
    //       if (response.message == "Success") {
    //         Swal.fire({
    //           title: "",
    //           text: "The Pay Rate has been Deleted Successfully!",
    //           icon: "success",
    //           confirmButtonClass: "btn btn-secondary",
    //         });
    //       }
    //     })
    //     .catch((ex) => {
    //       this.isRemoving = false;
    //       Swal.fire({
    //         title: "",
    //         text: "Error!",
    //         icon: "error",
    //         confirmButtonClass: "btn btn-secondary",
    //       });
    //     });
    // },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.edit_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #FB8C00 !important;
};
.del_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #BF360C !important;
}
</style>
